<template>
  <v-data-table
    :headers="headers"
    :items="assets.items"
    :options.sync="options"
    @update:options="updateOptions"
    :server-items-length="assets.count"
    :loading="loading"
    :no-data-text="$t('messages.emptyState', { entity: $tc('models.asset.entity') })"
    :no-results-text="$t('messages.emptyState', { entity: $tc('models.asset.entity') })"
    flat
    :style="{ cursor: isAdmin ? '' : 'pointer' }"
  >
    <template v-slot:[`item.assetType`]="{ item }">
      {{ $t(`models.asset.attributes.assetTypes.${item.assetType}`) }}
    </template>
    <template v-slot:[`item.assigneeOffice`]="{ item }">
      {{ item.assigneeOffice.value }}
    </template>
    <template v-slot:[`item.assigneeUser`]="{ item }">
      {{ item.assigneeUser? item.assigneeUser.value : '' }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn icon small @click="editAsset(item)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon small @click="deleteAsset(item)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';

export default ({
  name: 'assetsTable',
  data() {
    return {
      moment,
      expanded: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: ['false'],
        mustSort: ['true'],
        multiSort: false
      },
      headers: [
        {
          text: '',
          value: 'id',
          align: ' d-none'
        },
        {
          text: this.$t('models.asset.attributes.name'),
          value: 'name'
        },
        {
          text: this.$t('models.asset.attributes.description'),
          value: 'description'
        },
        {
          text: this.$t('models.asset.attributes.assetType'),
          value: 'assetType'
        },
        {
          text: this.$t('models.asset.attributes.serialNumber'),
          value: 'serialNumber'
        },
        {
          text: this.$t('models.asset.attributes.assigneeOffice'),
          value: 'assigneeOffice',
          align: this.showActions ? '' : ' d-none'
        },
        {
          text: this.$t('models.asset.attributes.assigneeUser'),
          value: 'assigneeUser',
          align: this.showActions ? '' : ' d-none'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '120px',
          align: this.showActions ? '' : ' d-none'
        }
      ]
    };
  },
  props: {
    assets: {
      type: Object,
      required: false
    },
    tableName: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    showActions: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    updateOptions(options) {
      this.$emit('updateOptions', options);
    },
    deleteAsset(item) {
      this.$emit('deleteAsset', item);
    },
    editAsset(item) {
      this.$emit('editAsset', item);
    }
  },
  computed: mapGetters(['currentUser', 'isAdmin'])
});
</script>

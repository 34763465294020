<template>
<v-container fluid class="px-lg-5">
  <div class="col-12">
    <app-modal
      v-show="isModalVisible"
      :modal="modal"
      @submit-modal="submitModal"
      @selection-changed="onOfficeChanged"
    />
    <app-header
      :title="title"
      :breadcrumbs="breadcrumbs"
      :actionButton="actionButton"
      @clicked="openCreateAssetModal"
      :showButton="true"
    />
    <v-card class="rounded-card" flat outlined>
      <assets-table
        @editAsset="editAsset"
        @deleteAsset="deleteAsset"
        @updateOptions="updateOptions"
        :assets="assets"
        :loading="loading"
        :showActions="true"
      >
      </assets-table>
    </v-card>
  </div>
</v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {assetTypes} from 'constants';
import assetsTable from 'components/assets/Table.vue';

export default {
  components: {assetsTable},
  data() {
    return {
      loading: false,
      isModalVisible: false,
      assetTypes: assetTypes,
      assetTypesMapped: [],
      modal: {
        show: false,
        id: '',
        behaviour: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          name: {
            type: 'text',
            key: 'name',
            value: '',
            label: this.$t('models.asset.attributes.name'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          assetType: {
            type: 'select',
            key: 'assetType',
            value: '',
            label: this.$t('models.asset.attributes.assetType'),
            options: this.assetTypesMapped,
            itemText: 'value',
            itemValue: 'key',
            classes: 'col-12 py-0',
            serverErrors: []
          },
          description: {
            type: 'text',
            key: 'description',
            value: '',
            label: this.$t('models.asset.attributes.description'),
            classes: 'col-12 py-0',
            serverErrors: []
          },
          serialNumber: {
            type: 'text',
            key: 'serialNumber',
            value: '',
            label: this.$t('models.asset.attributes.serialNumber'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          assigneeOffice: {
            type: 'select',
            key: 'assigneeOffice',
            value: '',
            options: [],
            itemText: 'name',
            itemValue: 'id',
            rules: [{name: 'requiredSelect'}],
            trackable: true,
            label: this.$t('models.asset.attributes.assigneeOffice'),
            classes: 'col-12 py-0',
            serverErrors: []
          },
          assigneeUser: {
            type: 'select',
            key: 'assigneeUser',
            value: '',
            options: [],
            itemText: 'name',
            itemValue: 'id',
            clearable: true,
            label: this.$t('models.asset.attributes.user'),
            classes: 'col-12 py-0',
            serverErrors: []
          }
        }
      },
      title: {
        text: this.$t('layout.mainMenu.assets'),
        icon: 'mdi-asset-building'
      },
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.company'),
          to: {name: 'Assets'},
          exact: true
        },
        {
          text: this.$t('layout.mainMenu.assets'),
          to: {name: 'Assets'},
          exact: true
        }
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      },
      actionButton: {
        text: this.$t('actions.create', {entity: this.$tc('models.asset.entity', 1)}),
        icon: 'mdi-plus'
      }
    };
  },
  computed: mapGetters(['assets', 'isAdmin', 'offices', 'users']),
  methods: {
    ...mapActions([
      'getUsers',
      'getOffices',
      'getAssets',
      'destroyAsset',
      'createAsset',
      'updateAsset'
    ]),
    updateOptions(options) {
      this.loading = true;
      this.getAssets(options).then(() => (this.loading = false));
    },
    manageasset(data) {
      this.loading = true;
    },
    submitModal(data) {
      let form;
      let request;
      switch (this.modal.behaviour) {
      case 'createAsset':
        request = this.createAsset(data);
        break;
      case 'updateAsset':
        form = {
          assetId: this.modal.id,
          form: data
        };
        request = this.updateAsset(form);
        break;
      }
      request.then((response) => {
        if (this.successCode(response.status)) {
          this.modal.show = false;
          this.updateOptions(this.options);
        } else {
          const assigneeField = this._.find(response.errors, (error) => error.field === 'assignee');
          if (assigneeField) {
            assigneeField.field = 'assigneeOffice';
          }
          this.displayInlineServerErrors(this.modal.fields, response.errors);
        }
      });
    },
    editAsset(data) {
      this.modal.behaviour = 'updateAsset';
      this.modal.title = this.$t('views.assets.updateAsset');
      this._.forEach(this.modal.fields, (field) => {
        this.modal.fields[field.key].value = data[field.key];
      });
      this.modal.fields.assigneeOffice.value = data.assigneeOffice.id;
      this.modal.fields.assigneeUser.value = data.assigneeUser ? data.assigneeUser.id : '';
      this.modal.id = data.id;
      this.onAssetEdited(data.assigneeOffice);
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    openCreateAssetModal() {
      this.modal.resetForm = true;
      this.clearInlineServerErrors(this.modal.fields);
      this.modal.title = this.$t('views.assets.newAsset');
      this._.forEach(this.modal.fields, (field) => {
        this.modal.fields[field.key].value = '';
      });
      this.modal.behaviour = 'createAsset';
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    deleteAsset(asset) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: asset.name}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyAsset(asset.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    },
    onOfficeChanged(data) {
      this.modal.fields.assigneeUser.value = '';
      this.getOfficeUsers(data.value);
    },
    onAssetEdited(data) {
      this.getOfficeUsers(data.id);
    },
    getOfficeUsers(officeId) {
      this.getUsers({filterBy: {officeId: officeId}, fields: ['firstName', 'lastName']})
        .then(() => {
          this.modal.fields.assigneeUser.options = this._.map(this.users.items, (user) => ({id: `${user.id}`, name: user.name}));
        });
    }
  },
  created() {
    this.assetTypesMapped = this._.map(this.assetTypes, (assetType, assetKey) => {
      return {key: assetKey, value: this.$t(`models.asset.attributes.assetTypes.${assetType}`)};
    });
    this.getOffices({fields: ['name']}).then(() => {
      this.modal.fields.assigneeOffice.options = this.offices.items;
    });
  },
  watch: {
    assetTypesMapped(val) {
      this.modal.fields.assetType.options = this.assetTypesMapped;
    }
  }
};
</script>
